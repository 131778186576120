import axios from 'axios'

axios.defaults.withCredentials = true

const state = {
  authenticated: false,
  need_change_password: false,

  company_name: 'YUMMY SEAFOODS RESTAURANT',

  branch_id: 0,
  branch_code: 'NA',
  branch_address: 'NA',
  branch_contact_no: 'NA',

  branch_id_selected: 1,
  branch_selected: 'NA',

  user_id: 0,
  id_no: 'GL-0-0000',
  name: 'NA',
  position: 'NA',

  can_new_month: false,
  is_franchise: false,

  month_of: 'NA',
  year: 'NA',
  month_start: 'NA',
  month_end: 'NA',
}

const mutations = {
  set_authenticated(state, authenticated) {
    state.authenticated = authenticated
  },
  set_need_change_password(state, need_change_password) {
    state.need_change_password = need_change_password
  },
  set_position(state, position) {
    state.position = position
  },

  set_branch_id(state, branch_id) {
    state.branch_id = branch_id
  },
  set_branch_id_selected(state, branch_id_selected) {
    state.branch_id_selected = branch_id_selected
  },
  set_branch_code(state, branch_code) {
    state.branch_code = branch_code
  },
  set_branch_address(state, branch_address) {
    state.branch_address = branch_address
  },
  set_branch_contact_no(state, branch_contact_no) {
    state.branch_contact_no = branch_contact_no
  },

  set_user_id(state, user_id) {
    state.user_id = user_id
  },
  set_id_no(state, id_no) {
    state.id_no = id_no
  },
  set_name(state, name) {
    state.name = name
  },

  set_can_new_month(state, can_new_month) {
    state.can_new_month = can_new_month
  },
  set_is_franchise(state, is_franchise) {
    state.is_franchise = is_franchise
  },

  set_month_of(state, month_of) {
    state.month_of = month_of
  },
  set_year(state, year) {
    state.year = year
  },
  set_month_start(state, month_start) {
    state.month_start = month_start
  },
  set_month_end(state, month_end) {
    state.month_end = month_end
  },
  set_branch_selected(state, branch_selected) {
    state.branch_selected = branch_selected
  },
}

const getters = {
  authenticated: state => state.authenticated,
  need_change_password: state => state.need_change_password,
  company_name: state => state.company_name,

  branch_id: state => state.branch_id,
  branch_id_selected: state => state.branch_id_selected,
  branch: state => state.branch_code,
  branch_selected: state => state.branch_selected,
  branch_address: state => state.branch_address,
  branch_contact_no: state => state.branch_contact_no,

  user_id: state => state.user_id,
  id_no: state => state.id_no,
  position: state => state.position,
  name: state => state.name,

  can_new_month: state => state.can_new_month,
  is_franchise: state => state.is_franchise,

  month_of: state => state.month_of,
  year: state => state.year,
  month_start: state => state.month_start,
  month_end: state => state.month_end,

}

const actions = {
  change_branch_id: ({commit}, data) => {
    commit('set_branch_id_selected', data.branch_id)
    commit('set_branch_selected', data.branch_code)
    commit('set_branch_contact_no', data.contact_no)
    commit('set_branch_address', data.address)
  },
  login_credentials({commit, rootGetters}, data) {
    axios.defaults.baseURL = rootGetters['system_data/url']
    return new Promise((resolve, reject) => {
      axios
        .get('/sanctum/csrf-cookie')
        .then(() => {
          axios
            .post('api/login-user-profile', data)
            .then(response => {
              if (
                response.data === 'The provided USER INFO are incorrect.' ||
                response.data === 'The USER is already Login' ||
                response.data === 'Unrecognize Device'
              ) {
                commit('set_authenticated', false)
              } else {
                commit('set_authenticated', true)

                commit('set_need_change_password', response.data.is_change_password)
                commit('set_name', response.data.name)
                commit('set_user_id', response.data.id)
                commit('set_id_no', response.data.id_no)
                commit('set_position', response.data.position)

                commit('set_branch_id', response.data.branch_id)
                commit('set_branch_code', response.data.branch.branch_code)
                commit('set_branch_address', response.data.branch.address)
                commit('set_branch_contact_no', response.data.branch.contact_no)

                commit('set_is_franchise', response.data.branch.is_franchise)

              }
              resolve(response)
            })
            .catch(error => {
              reject(error)
            })
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  logout_credentials({commit, rootGetters}, data) {
    axios.defaults.baseURL = rootGetters['system_data/url']
    return new Promise((resolve, reject) => {
      axios
        .post('api/logout-user-profile', data)
        .then(response => {
          commit('set_authenticated', false)

          commit('set_position', 'NA')
          commit('set_user_id', 0)
          commit('set_id_no', 'CHAP-0-0000')
          commit('set_name', 'NA')

          commit('set_branch_code', 'NA')
          commit('set_branch_address', 'NA')
          commit('set_branch_contact_no', 'NA')

          commit('set_can_new_month', false)
          commit('set_is_franchise', false)

          commit('set_month_of', 'NA')
          commit('set_year', 'NA')
          commit('set_month_start', 'NA')
          commit('set_month_end', 'NA')
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
}

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations,
}
