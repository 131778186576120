import Vue from 'vue'
import Vuex from 'vuex'
import SecureLS from 'secure-ls'
import createPersistedState from 'vuex-persistedstate';
import system_data from './module/system'
import form_rules from './module/form_rules'
import authentication from './module/authentication'
import users from './module/user'
import transaction_months from './module/data/transaction_months'
import bank_depository from './module/data/bank_depository'
import deposit_slip from './module/data/deposit_slip'
import branch_information from './module/data/branch_information'
import table from './module/data/table'
import menu_order from './module/data/menu_order'
import table_order from './module/data/table_order'
import purchase_order_slip from './module/data/purchase_order_slip'
import transaction_months_history from './module/data/transaction_months_history'
import inventory from './module/data/inventory'


const ls = new SecureLS({
  isCompression: false,
})
Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [createPersistedState(
    {
      key: 'YUMMY-SEA-FOODS',
      paths: ['authentication'],
      storage: {
        getItem: (key) => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: (key) => ls.remove(key),
      },
    },
  )],
  state: {},
  mutations: {},
  actions: {},
  modules: {
    system_data,
    form_rules,
    authentication,
    users,
    transaction_months,
    bank_depository,
    deposit_slip,
    branch_information,
    table,
    menu_order,
    table_order,
    purchase_order_slip,
    transaction_months_history,
    inventory,
  },
})
