<template>
  <v-app>
    <vertical-nav-menu :is-drawer-open.sync="isDrawerOpen"></vertical-nav-menu>

    <v-app-bar
      app
      flat
      absolute
      color="transparent"
    >
      <div class="w-full">
        <div class="d-flex align-center ">
          <!-- Left Content -->
          <v-app-bar-nav-icon
            class="me-2"
            @click="isDrawerOpen = !isDrawerOpen"
          ></v-app-bar-nav-icon>
          <span class="d-sm-inline d-none">
            Operation: <a
            href=""
            class="text-decoration-none"
          >{{month_of}}</a>
          </span>
          <v-spacer></v-spacer>
          <v-select
            v-if="position==='ADMIN'"
            class="mt-5 mx-2"
            v-model="branch"
            :items="branch_items"
            item-text="branch_code"
            item-value="id"
            label="Branch"
            dense
            outlined
            @change="change_db(branch_items[branch_items.map(function (x) {
                    return x.id;
                }).indexOf(branch)])"
          ></v-select>
          <!-- Right Content -->
          <theme-switcher></theme-switcher>
          <v-btn
            icon
            small
            class="ms-3"
          >
            <v-icon>
              {{ icons.mdiBellOutline }}
            </v-icon>
          </v-btn>
          <app-bar-user-menu></app-bar-user-menu>
        </div>
      </div>
    </v-app-bar>

    <v-main :key="branch_id_selected">
      <div class="app-content-container  pa-6">
        <slot></slot>
      </div>
    </v-main>

    <v-footer
      app
      inset
      color="transparent"
      absolute
      height="56"
      class="px-0"
    >
      <div class="boxed-container w-full">
        <div class="mx-6 d-flex justify-space-between">
          <span>
            &copy; 2023 - {{this_year}}<a
            href="https://www.facebook.com/goodlife.itd/"
            class="text-decoration-none"
            target="_blank"
          > GOODLIFE IT DEPARTMENT</a></span>
        </div>
      </div>
    </v-footer>
  </v-app>
</template>

<script>
  import {ref} from '@vue/composition-api'
  import {mdiMagnify, mdiBellOutline, mdiGithub} from '@mdi/js'
  import VerticalNavMenu from './components/vertical-nav-menu/VerticalNavMenu.vue'
  import ThemeSwitcher from './components/ThemeSwitcher.vue'
  import AppBarUserMenu from './components/AppBarUserMenu.vue'
  import moment from "moment";
  import {mapGetters, mapActions} from 'vuex'

  export default {
    components: {
      VerticalNavMenu,
      ThemeSwitcher,
      AppBarUserMenu,
    },
    setup() {
      const isDrawerOpen = ref(null)

      return {
        isDrawerOpen,
        this_year: moment().format('YYYY'),

        month_of: '',
        branch: '',
        branch_items: [],
        key: 0,
        // Icons
        icons: {
          mdiMagnify,
          mdiBellOutline,
          mdiGithub,
        },
      }
    },
    created() {
      this.month_of = moment().format('MMMM DD, YYYY')
      if (this.position === 'ADMIN' || this.position === 'MANAGER') {
        this.get_branches_active()
          .then(response => {
            this.change_branch_id({
              branch_id: 1,
              branch_code: 'ADMIN',
              address: 'NA',
              contact_no: 'NA',
            })
            this.branch_items = response.data
          })
          .catch(error => {
            console.log(error)
          })
      }
    },
    computed: {
      ...mapGetters('authentication', ['position', 'branch_id_selected']),
    },
    methods: {
      ...mapActions('branch_information', ['get_branches_active']),
      ...mapActions('authentication', ['change_branch_id']),
      change_db(data) {
        this.change_branch_id({
          branch_id: data.id,
          branch_code: data.branch_code,
          address: data.address,
          contact_no: data.contact_no,
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
  .v-app-bar ::v-deep {
    .v-toolbar__content {
      padding: 0;

      .app-bar-search {
        .v-input__slot {
          padding-left: 18px;
        }
      }
    }
  }

  .boxed-container {
    max-width: 1440px;
    margin-left: auto;
    margin-right: auto;
  }
</style>
